import { StoreOptions } from 'vuex'
import { UPDATE_USER } from './type'




const userInfo: StoreOptions<{}> = {
    state: () => ({
        userInfo: {
            a:'1111'
        }
    }),
    getters: {
        getUser(state: any) {
            return state.userInfo
        }
    },
    mutations: {
        [UPDATE_USER](state: any, data) {
            state.userInfo = data
        }
    }
}


export default userInfo









