export default  {
    "Index": "https://www.weiyel.com/",
    "Interest": "https://www.weiyel.com/",
    "News": "https://www.weiyel.com/nlist/nlist_0_1.html",
    "NewsDetail": "https://www.weiyel.com/html_news/news_{{newsid}}_1.html",
    "Forget": "https://www.weiyel.com/",
    "About": "https://www.weiyel.com/about.html",
    "product": "https://www.weiyel.com/plist_1/plist_1_0_0_1.html",
    "customize": "https://www.weiyel.com/Product/SearchOpenSearchNo.html",
    "Order": "https://www.weiyel.com/User/NeiOrders.html",
    "UserInfo": "https://www.weiyel.com/User/UserInfo.html",
    "FootPrint": "https://www.weiyel.com/",
    "Message": "https://www.weiyel.com/",
    "Account": "https://www.weiyel.com/User/index.html",
    "ShopCar": "https://www.weiyel.com/ShoppingCart",
    "SubmitOrder": "https://www.weiyel.com/ShoppingCart/Checkout",
    "OpenSearch": "https://www.weiyel.com/",
    "signIn": "https://www.weiyel.com/User/index.html",
    "Address": "https://www.weiyel.com/User/Address",
    "addAddress": "https://www.weiyel.com/User/Address",
    "Chat": "https://www.weiyel.com",
    "OrderDetail": "https://www.weiyel.com/User/OrderDetail?num={{num}}",
    "Login": "https://www.weiyel.com/",
    "Home": "https://www.weiyel.com/",
    "Mine": "https://www.weiyel.com/User/index.html",
    "Search": "https://www.weiyel.com/",
    "Error": "https://www.bzwz.com/"
}
