import { StoreOptions } from 'vuex'


import { UPDATE_LOGIN } from './type'

const login: StoreOptions<{}> = {
    state: () => {
        return {
            show: false
        }
    },
    mutations: {
        [UPDATE_LOGIN](state: any) {
            state.show = !state.show
        }
    }
}


export default login









