<template>
  <div :class="{ 'header-left-wrap': true, 'header-content-show': isMenuHide }">
    <div class="header-left-bg" @click="handleHide"></div>
    <div
      :class="{ 'header-left-content ': true, 'header-left-content-show': isMenuHide }"
    >
      <div class="header-left-top">
        <div class="header-left-user">
          <router-link
            v-if="userInfo.id"
            :to="{ name: 'Mine' }"
          >
            <img
              :src="
                userInfo.userImg ||
                'https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg'"
              @click="toSignin"
            />
          </router-link>
          <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/slider-logo.png" v-else @click="toLogin" />
        </div>
        <div class="header-left-text">
          <span v-if="userInfo.id">{{ userInfo.nickName }}</span>
          <span @click="toLogin" v-else></span>
          <p v-if="userInfo.id">{{ userInfo.personalSignature }}</p>
          <p @click="toLogin" v-else>Login</p>
        </div>
      </div>
      <div class="header-left-main-wapper">
        <div>
          <ul class="header-left-main" style="border: none">
            <li>
              <router-link
                :to="{ name: 'ProductCategory'}"
                @click="handleHide"
              >
                <img
                  src="@/assets/img/leftNav/icon16.png"
                  style="left: 12px"
                />
                <span>Products</span>
              </router-link>
            </li>
            <li>
              <router-link
                      @click="handleHide"
                      :to="{name: 'customize',}">
                  <img style="width: 25px; height: 25px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/m-home-dz20201027.png" alt=""/>
              <span>Customized</span>

              </router-link>
            </li>
          </ul>
<!--          <ul class="header-left-main">-->
<!--            <li>-->
<!--              <router-link @click="handleHide" :to="{ name: 'News'}">-->
<!--                <img-->
<!--                  style="width: 0.6rem"-->
<!--                  src="@/assets/img/leftNav/icon12.png"-->
<!--                />-->
<!--                &lt;!&ndash; src="https://www.bzwz.com/static/m/images/icon12.png" &ndash;&gt;-->
<!--                <span>News</span>-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
          <ul class="header-left-main">
            <li>
              <router-link @click="handleHide" :to="{ name: 'About'}">
                <img
                  style="width: 0.707rem"
                  src="@/assets/img/icon_phone.svg"
                />
                <span>About us</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'ShopCar'}">
                <img
                  style="width: 0.6rem"
                  src="@/assets/img/common/icon23-1.png"
                />
                <!-- src="https://www.bzwz.com/static/m/images/icon23-1.png" -->
                <span>Shopping Cart</span>
              </router-link>
            </li>
            <li>
              <router-link @click="handleHide" :to="{ name: 'Index'}">
                <img
                  style="width: 0.6rem"
                  src="@/assets/img/icon22.png"
                />
                <span>Home</span>
              </router-link>
            </li>
            <li>
              <a href="https://twitter.com/RMWeiye2022" target="_blank">
                <img style="width: 26px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/227f6cc708259a57b896bbb8723eb164.png" alt="">
                <span>Twitter</span>
              </a>

            </li>
            <li>
              <a href="https://www.linkedin.cn/incareer/in/ACoAAD4ehfcBVhBXVhQKuNGlOOGW3JsbxGtWzF4" target="_blank">
                <img style="width: 26px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/d5febefe569b79b550a77c3c6602c1a2.png" alt="">
                <span>In</span>
              </a>

            </li>
            <li>
              <a href="https://m.me/100175782877277" target="_blank">
                <img style="width: 26px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/6f9e4e57dcbc3d935b37fc1b375b4e79.png" alt="">
                <span>Facebook</span>
              </a>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  props: {
    isMenuHide: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const hide = inject("handleMenuHide");
    const isHeaderHide = inject("isHeaderHide")
    const router = useRouter();
    const route = useRoute();
    function handleHide() {
      (hide as () => void)();
    }

    //  展示用户信息
    const userInfo = inject("userInfo") as any;
    function toLogin() {
      (hide as () => void)();
      if(route.path != "/login"){
        // router.push({
        //   name: "Login",
        //   query: {
        //     redirect: route.fullPath
        //   }
        // })
        router.push({
          name: "Mine",
        })
      }else{
        // router.push({
        //   name: "Login",
        // })
        router.push({
          name: "Mine",
        })
      }

    }

    function toSignin() {
        (hide as () => void)();
    }

    return {
      handleHide,
      toLogin,
      userInfo,
      toSignin
    };
  },
});
</script>

<style lang="scss" scoped>
/* 头部侧边栏弹出窗 start*/
.header-left-wrap {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10000;
  overflow: hidden;
  transform: translateX(-100%);
}
.header-content-show {
  transform: translateX(0);
}

.header-left-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  /* // opacity: 0; */
  opacity: 1;
}

.header-left-content {
  width: 274.988px;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  /* // left: -100%; */
  left: 0;
  transform: translateX(-274.988px);
  z-index: 1000;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  transition: transform 0.3s;
}
.header-left-main-wapper {
  height: 80%;
  padding-bottom: 154.988px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.header-left-content-show {
  transform: translateX(0);
  transition: transform 0.5s;
}

.header-left-top {
  width: 100%;
  height: 105px;
  background-color: #d64b4b;
  box-sizing: border-box;
  padding-left: 10.013px;
  overflow: hidden;
  padding-right: 10.013px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.header-left-user {
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  float: left;
}

.header-left-user img {
  width: 100%;
  height: 100%;
}

.header-left-text {
  width: 115.013px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 15px;
  color: #ffffff;
}

.header-left-text span {
  font-size: 16.012px;
  color: #ffffff;
}

.header-left-text p {
  width: 100%;
  color: #ffffff;
  font-size: 12px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-left-sign {
  display: block;
  width: 67.987px;
  height: 30px;
  border-radius: 15px;
  border: solid 1.012px #ffffff;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 13.988px;
  box-sizing: border-box;
}

.header-left-main {
  width: 100%;
  margin-top: 4.988px;
  /* padding-top: 10.013px; */
  border-top: 2px solid #f4f4f4;
}

.header-left-main li {
  width: 100%;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  padding: 0 10.013px;
  padding-left: 49.987px;
  position: relative;
}

.header-left-main img, .header-left-main svg {
  width: 17.512px;
  height: auto;
  border: none;
  position: absolute;
  left: 10.013px;
  top: 50%;
  transform: translateY(-40%);
}

.header-left-main li span {
  color: #666666;
  font-size: 16.012px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: -2px;
}
/* 头部侧边栏弹出窗 end*/
</style>
