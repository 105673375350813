
import { App } from 'vue'
import Toast from './src/toast'



(Toast as any).install = (app: App): void => {
    app.config.globalProperties.$toast = Toast
}

export default Toast
