import { StoreOptions } from 'vuex'
import { RouteLocationNormalized } from "vue-router";

import { UPDATE_TO_ROUTER, UPDATE_FROM_ROUTER } from './type'

const login: StoreOptions<{}> = {
    state: () => {
        return {
            to: null,
            from: null,
        }
    },
    mutations: {
        [UPDATE_TO_ROUTER](state: any, to: RouteLocationNormalized) {
            state.to = to
        },
        [UPDATE_FROM_ROUTER](state: any, from: RouteLocationNormalized) {
            state.from = from
        }
    }
}


export default login









