
import { createVNode, render } from 'vue'

import type { VNode } from 'vue'

import ToastConstructor from './Toast.vue'

const container = document.getElementById('toast')
let vm: VNode;
type toastType = "success" | "error" | "warning" | "default";

type ToastOption = {
    type?: toastType;
    title: string;
    icon?: string;
    modal?: boolean;
    duration?: number;
    onClose?: () => void;
}




type Toast = (params: ToastOption) => void



const Toast: Toast = function (toastOptions: ToastOption) {
    const options = {
        type: toastOptions.type || 'default',
        title: toastOptions.title,
        icon: toastOptions.icon || '',
        modal: toastOptions.modal || false,
        duration: toastOptions.duration || 1500,
        onClose: toastOptions.onClose
    }
    vm = createVNode(ToastConstructor, options);
    render(vm, container as HTMLElement)

    const timer = setTimeout(() => {
        if (options.onClose) {
            options.onClose()
        }
        render(null, container as HTMLElement)
        clearTimeout(timer);

    }, options.duration)
}



export default Toast
