import { createStore } from 'vuex'

import userInfo from './modules/user'
import login from './modules/login'
import router from './modules/router'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userInfo,
    login,
    router
  }
})
